import Header from "../Header";
import Footer from "../Footer";
import { Link } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import { useState } from "react";
import Video_Junior from "../Video_Junior";
import Countdown from "../Countdown";
import AudioPlayer from "./AudioPlayer";


function SpreadTheWord() {


  // =======TIME TO END===
  const offerEndDate = "2024-10-15T00:00:00";


  return (
    <div>
      <Header />


      <div className="speaking_slider_all d-flex justify-content-center px-4">
        <div className="col-sm-10 py-4">
          <div className="row">

            <div className="col-sm-6">
              <div class="container_video">
                <iframe class="responsive-iframe" src="https://player.vimeo.com/video/1008550135" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen ></iframe>
                <script src="https://player.vimeo.com/api/player.js"></script>
              </div>

            </div>

            <div className="col-sm-6 align-self-center">
              <center>
                <div className="px-2 py-3">
                  <div className="text2_speaking_slider">HELP US HIT 1,000 REVIEWS <br />BEFORE THE BOOK LAUNCH!</div>
                  <div className="text3_speaking_slider col-sm-10  mt-2 ">
                    Help us hit 1,000 reviews on Amazon by launch day—October 15, 2024—and enjoy exclusive VIP access to the book today!                  </div>
                  <br />

                  <center>
                    <a className="btn_spread001 " href="https://lp.constantcontactpages.com/sl/iNhEW30/persistbook">UNLOCK ACCESS NOW</a>
                  </center>

                </div>
              </center>
            </div>

          </div>
        </div>
      </div>




      {/* ===========Header notification======== */}
      <div className='header_time header_time_new '>
        <div className='col-sm-8 m-auto py-3'>
          <div class="row">
            <div class="col-md-auto">
              <div className='align-self-center d-none d-sm-block'><img className='img_time' src={'/images/icons/clock2.png'} /></div>
              <div className='align-self-center d-block d-sm-none'><img className='img_time' src={'/images/icons/clock2.png'} /></div>
            </div>
            <div class="col-md-auto">
              <div className=' '>
                <div className='time_text1'> CLAIM SPECIAL DEAL-HURRY UP!</div>
                <div className='text0_spread'>Note: This special pre-launch offer could end at any moment...</div>
                <div className='time_text3'> <Countdown targetDate={offerEndDate} /></div>
              </div>
            </div>
            <div class="col-md-auto">
              <div className=' align-self-center mb-3 mt-4 '>
                <span className="text1_spread">$21.99</span>  <span className="text2_spread">$2.99</span>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<a className="btn_spread001 " href="https://lp.constantcontactpages.com/sl/iNhEW30/persistbook">PRE-ORDER NOW!</a>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ====================== */}

      <div className="bg-white py-4">
        <div className="container">
          <center>
            <h3 className="text-center mt-3">SECURE YOUR DEAL IN 3 SIMPLE STEPS</h3>


            <div className="col-sm-9 m-auto py-3 ">
              <div className="row">
                <div className="col-sm-4 border_spread001 mb-4">
                  <img src={'/images/book/12.png'} className="img_spread002" />
                  <div className="text_spread_box001 mb-2">STEP 1</div>
                  {/* <div className="text_spread_box002">PRE-ORDER BOOK TODAY <br /> ON AMAZON</div> */}
                  <div className="text_spread_box003">
                    Pre-order Persist, Persist, Persist. on<br />
                    <a href="https://lp.constantcontactpages.com/sl/iNhEW30/persistbook" className="text_spread_box004">Amazon</a>  for just $2.99 before <br /> the price increases!
                  </div>
                </div>
                <div className="col-sm-4 border_spread001 mb-4">
                  <img src={'/images/book/11.png'} className="img_spread002" />
                  <div className="text_spread_box001 mb-2">STEP 2</div>
                  {/* <div className="text_spread_box002">SHARE A SCREENSHOT & GET ACCESS<br /> TO THE BOOK NOW</div> */}
                  <div className="text_spread_box003">Email a screenshot of your purchase to<br /> <span className="text_spread_box004">hello@juniorpersist.com </span> and get INSTANT <br />ACCESS to the book!</div>
                </div>
                <div className="col-sm-4 mb-4">
                  <img src={'/images/book/13.png'} className="img_spread002" />
                  <div className="text_spread_box001 mb-2">STEP 3</div>
                  {/* <div className="text_spread_box002">POST A REVIEW ON AMAZON<br /> ON LAUNCH DAY</div> */}
                  <div className="text_spread_box003">
                    <a href="https://lp.constantcontactpages.com/sl/iNhEW30/persistbook" className="text_spread_box004">Post a review on Amazon</a> on our book <br />launch day-October 15th!<br /> (Don’t worry, we’ll remind you.)
                  </div>
                </div>
              </div>
            </div>

            <div className="">
              <a className="btn_spread002 " href="https://lp.constantcontactpages.com/sl/iNhEW30/persistbook">GRAB YOUR EBOOK COPY NOW AT $2.99!</a>
            </div>

          </center>
        </div>
      </div>


      <div className="bg-black mt-4">
        <br></br>
        <div className="container col-sm-8 py-4 ">
          <center>
            <div className="jett_scrollmenu">
              <div className="col-sm-6 jett_scrollmenu_in">
                <div className="text_spread_box006">BOOK TRAILER</div>
                <div className="video_part_cont video_part_cont_new py-4">
                  <iframe className='iframe_part3' src="https://player.vimeo.com/video/1001246468" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
                </div>
              </div>



              <div className="col-sm-6 jett_scrollmenu_in">
                <div className="text_spread_box006">PRAISES</div>
                <div className="video_part_cont video_part_cont_new py-4">
                  <iframe className='iframe_part3' src="https://player.vimeo.com/video/957777985" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
                </div>
              </div>

            </div>


            <div className="col-sm-6 m-auto">
              <div className="new_audio0398 px-2 py-2">AUDIO EXCERPT</div>
              <div className="py-2">
                <AudioPlayer />
              </div>
            </div>

          </center>
        </div>

      </div>


      <div className="bg-white py-5">
        <center>
          <div className="text_spread_box005 text-black">GRAB THE EBOOK AND GAIN <a className="text_spread_box004" href="https://lp.constantcontactpages.com/sl/iNhEW30/persistbook"> VIP ACCESS</a> <br /> NOW BEFORE IT’S TOO LATE!</div>
          <img src={'/images/book/12.png'} className="img_spread002 py-1" />
          <div className="mt-2">
            <a className="btn_spread002 " href="https://lp.constantcontactpages.com/sl/iNhEW30/persistbook">SECURE THIS OFFER NOW!</a>
          </div>
        </center>
      </div>

    
    
        
      {/* ===========Header notification======== */}
      <div className='header_time header_time_new '>
        <div className='col-sm-8 m-auto py-3'>
          <div class="row">
            <div class="col-md-auto">
              <div className='align-self-center d-none d-sm-block'><img className='img_time' src={'/images/icons/clock2.png'} /></div>
              <div className='align-self-center d-block d-sm-none'><img className='img_time' src={'/images/icons/clock2.png'} /></div>
            </div>
            <div class="col-md-auto">
              <div className=' '>
                <div className='time_text1'> CLAIM SPECIAL DEAL-HURRY UP!</div>
                <div className='text0_spread'>Note: This special pre-launch offer could end at any moment...</div>
                <div className='time_text3'> <Countdown targetDate={offerEndDate} /></div>
              </div>
            </div>
            <div class="col-md-auto">
              <div className=' align-self-center mb-3 mt-4 '>
                <span className="text1_spread">$21.99</span>  <span className="text2_spread">$2.99</span>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<a className="btn_spread001 " href="https://lp.constantcontactpages.com/sl/iNhEW30/persistbook">PRE-ORDER NOW!</a>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ====================== */}




      {/* ============================Part 2 */}

      {/* <div className="spread">
        <div className="container py-4">
          <center>

            <div className=" d-none d-sm-block"> <br /> <br /> <br /> </div>


            <div className="spread_box mt-3 py-4">HOW TO SPREAD THE WORD </div>

            <div className="container px-5 d-none d-sm-block">
              <img className="spread_img1" src={'/images/new2/spread/4.png'} />
            </div>



            <div className="col-sm-6 m-auto ">
              <div className="spread_text2 py-4">
                It's super easy! In less than a minute, you can take action by copying your favorite image and caption below to share on social media!
              </div>
              <div className="">
                <img className="w-100" src={'/images/new2/spread/1.png'} />
                <div className="mt-4">
                  <a download href={'/images/new2/spread/1.png'} className="btn_spread">DOWNLOAD IMAGE</a>
                </div>
              </div>

              <div className="mt-4">
                <span className="spread_text3">COPY CAPTION:</span>
                <p>
                  This Haitian author dedicated 7 years to crafting his book. Today, his dream is to inspire a million people with it.
                  Let's help make it happen. Get a book. Gift one. Tell 3 others!<br />
                  https://juniorpersist.com<br />
                  #persist,persist,persist.
                </p>
              </div>



              <div className="mt-5">
                <img className="w-100" src={'/images/new2/spread/2.png'} />
                <div className="mt-4">
                  <a download href={'/images/new2/spread/2.png'} className="btn_spread">DOWNLOAD IMAGE</a>
                </div>
              </div>
              <div className="mt-4">
                <span className="spread_text3">COPY CAPTION:</span>
                <p>
                  Calling all young professionals and college students seeking
                  epic inspiration! Check out the book, "Persist, Persist, Persist."
                  Get a book. Gift one. Tell 3 others!<br />
                  https://juniorpersist.com<br />
                  #persist,persist,persist.
                </p>
              </div>


              <div className="mt-5">
                <img className="w-100" src={'/images/new2/spread/3.png'} />
                <div className="mt-4">
                  <a download href={'/images/new2/spread/3.png'} className="btn_spread">DOWNLOAD IMAGE</a>
                </div>
              </div>
              <div className="mt-4">
                <span className="spread_text3">COPY CAPTION:</span>
                <p>
                  Don't miss out on the book, "Persist, Persist, Persist" — it's a
                  powerful story you won't want to miss!
                  Get a book. Gift one. Tell 3 others!<br />
                  https://juniorpersist.com<br />
                  #persist,persist,persist.
                </p>
              </div>


            </div>
          </center>
        </div>
        <br />  <br />
      </div> */}





      <Footer />
    </div>
  )
}



export default SpreadTheWord