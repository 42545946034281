import Header from "../Header";
import Footer from "../Footer";
import { Link } from "react-router-dom";
function PreOrder_success() {


  return (
    <div>
      <Header />

      <div className="success_access_IA py-4 ">

        <div className="success_access_IA_border_down">
          <div className="text0_success_access_IA col-sm-4 m-auto py-2">CONGRATULATIONS!</div>
        </div>

        <center>

          <div className="row col-sm-4 m-auto py-2 mt-1">
            <div className="col-sm-6 mb-4">
              <a href="/Reaction" className="btn1_success_access_IA">LEAVE A REVIEW</a>
            </div>
            <div className="col-sm-6 mb-4">
              <a href="https://ayisyenkapab.com/persist-collection" className="btn1_success_access_IA">SHOP OUR STORE</a>
            </div>
          </div>

          <div className="mt-2">
            <div className="text1_success_access_IA">GET READY FOR AN INCREDIBLE RIDE!</div>
            <div className="text2_success_access_IA">Please proceed to access your eBook, audiobook, or both using the code provided below.</div>
          </div>

          <div className="mt-4">
            <span className="box1_success_access_IA">0024</span>
            <div className="text3_success_access_IA mt-2">ACCESS CODE</div>
          </div>

          <div className="col-sm-10 mt-5">
            <div className="bg-black py-4 px-3">
              <div className="col-sm-7 row">
                <div className="col-sm-6 boder_0974 mb-5">
                  <a href="https://juniorpersist.com/public/public/accesses/accessBook" className="btn2_success_access_IA">&nbsp;&nbsp;&nbsp;ACCESS THE EBOOK&nbsp;&nbsp;&nbsp;</a>
                  <img src={'images/book/ebookNew.png'} className="w-100" />
                </div>
                <div className="col-sm-6">
                  <a href="https://juniorpersist.com/public/public/accesses/accessAudioBook" className="btn2_success_access_IA">ACCESS THE AUDIOBOOK</a>
                  <img src={'images/book/audiobookNew.png'} className="w-100" />
                </div>
              </div>
            </div>
          </div>

          <div className="text2_success_access_IA mt-4 px-2">
            The eBook and audiobook are available to you today and for 30 days<br /> after launch, once your copies arrive!
          </div>

        </center>
      </div>







      {/* 
      <div className="book">
        <center>
          <section className="mail-seccess section py-3">
            <div className="container ">
              <center>
                <div className="col-lg-6 ">
                  <div className="success-inner">
                    <h1><i className="fa fa-check"></i><span>Your pre-order has been completed successfully!</span></h1>
                    <p className="jett87345_1">
                      Thank you for your pre-order. We will notify you once the book is ready for print.<br />
                      Questions? <Link className="btn2_space_jett98_b2" to={'/Contact'}>Contact us today!</Link>
                    </p>
                  </div>
                </div>
              </center>
            </div>
            <br /><br /><br />
          </section>
        </center>
      </div> */}



      <div className="mt-5">
        <div className="buy_por984774_text1 mt-3">SHOP THE PERSIST COLLECTION</div>
        <div className="buy_por984774_text2">LET'S SPREAD A POSITIVE VIBE TOGETHER!</div>
        <div className="mt-4">
          <center>
            <a className="buy_box_btn px-5" target="_blank" href="https://ayisyenkapab.com/persist-collection/">SHOP NOW</a>
          </center>
          <img className="w-100 mt-3" src={'/images/new/pub_img1.png'} />
        </div>
      </div>













      <Footer />
    </div>
  )
}


export default PreOrder_success