import Header from "../Header";
import Footer from "../Footer";
import { Link } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import { useState } from "react";
import Video_Junior from "../Video_Junior";
import Countdown from "../Countdown";
import AudioPlayer from "./AudioPlayer";


function ClaimSpecial() {

  const [showMore, setShowMore] = useState(false);

  const handleToggle = () => {
    setShowMore(!showMore);
  };

  // =======TIME TO END===
  const offerEndDate = "2024-10-15T00:00:00";


  return (
    <div>
    


      <div className="speaking_slider_all d-flex justify-content-center px-4 py-5">
        <div className="col-sm-10 py-4">
          <div className="row">

            <div className="col-sm-6">
              <div class="container_video">
                <iframe class="responsive-iframe" src="https://player.vimeo.com/video/1009138592" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen ></iframe>
                <script src="https://player.vimeo.com/api/player.js"></script>
              </div>

            </div>

            <div className="col-sm-6 align-self-center">
              <center>
                <div className="px-2 py-3">
                  <div className="text2_speaking_slider">PRE-ORDER NOW AND GAIN EARLY ACCESS</div>
                  <div className="text3_speaking_slider col-sm-10  mt-2 ">
                    Pre-order 'Persist, Persist, Persist.' now to instantly access the eBook and audiobook of this highly anticipated memoir—weeks before anyone else!
                  </div>
                  <div className="text_spread_box004">This is a limited-time offer!</div>
                  <br />

                  <center>
                    <a className="btn_spread001 " href="/BuyPhysicalBook">UNLOCK INSTANT ACCESS</a>
                  </center>

                </div>
              </center>
            </div>

          </div>
        </div>
      </div>




      {/* ===========Header notification======== */}
      <div className='header_time header_time_new '>
        <div className='col-sm-8 m-auto py-3'>
          <div class="row">
            <div class="col-md-auto">
              <div className='align-self-center'>
                <img className='img_time' src={'/images/icons/clock/1.gif'} />
              </div>
            </div>
            <div class="col-md-auto">
              <div className=' mt-2'>
                <div className='time_text1 mt-2'> CLAIM THIS SPECIAL DEAL—HURRY!</div>
                <div className='text0_spread'>Note: This special pre-launch offer could end in any minute...</div>
                {/* <div className='time_text3'> <Countdown targetDate={offerEndDate} /></div> */}
              </div>
            </div>
            <div class="col-md-auto">
              <div className=' align-self-center mb-3 mt-4 '>
                <span className="text1_spread">$21.99</span>  <span className="text2_spread">$18.69</span>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<a className="btn_spread001 " href="/BuyPhysicalBook">PRE-ORDER NOW!</a>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ====================== */}

      <div className="bg-white py-2 mt-2">
        <div className="container">
          <center>
            <h3 className="text-center mt-3">SECURE THIS DEAL NOW!</h3>


            <div className="col-sm-8 m-auto py-3 ">
              <div className="row">

                <div className="col-sm-6 border_spread001 mb-4">
                  <img src={'/images/book/4.png'} className="img_spread003" />
                  <div className="text_spread_box001 mb-2">PRE-ORDER 1 PAPERBACK COPY</div>
                  {/* <div className="text_spread_box002">SHARE A SCREENSHOT & GET ACCESS<br /> TO THE BOOK NOW</div> */}
                  <div className="text0_spread">
                    Get early access to both the <span className="text_spread_box004">eBook</span> and <span className="text_spread_box004">audiobook</span><br />—perfect for enjoying on the go or while driving!


                  </div>
                  <div className="mt-2">
                    <a className="btn_spread002 " href="/BuyPhysicalBook">GRAB DEAL NOW</a>
                  </div>

                </div>
                <div className="col-sm-6 mb-4">
                  <img src={'/images/book/5.png'} className="img_spread004" />
                  <div className="text_spread_box001 mb-2">PRE-ORDER 3 PAPERBACK COPIES TO GIFT</div>
                  {/* <div className="text_spread_box002">POST A REVIEW ON AMAZON<br /> ON LAUNCH DAY</div> */}
                  <div className="text0_spread">
                    Get early access to both the <span className="text_spread_box004">eBook</span> and <span className="text_spread_box004">audiobook</span><br />—perfect for enjoying on the go or while driving!

                    {showMore && (
                      <span className="text_spread_box008">
                        <br />
                        Plus, receive an invitation to a special virtual meet-and-greet with the author on launch day,
                        and a chance to win signed copies and more!
                      </span>
                    )}

                    <button className="btn_viewmore" onClick={handleToggle}>
                      {showMore ? 'View Less...' : 'View More...'}
                    </button>



                  </div>
                  <div className="mt-2">
                    <a className="btn_spread002 " href="/BuyPhysicalBook">GRAB DEAL NOW</a>
                  </div>
                </div>
              </div>


              <center>
                <div className="text_spread_box007 mt-4 opacity-50">
                  The eBook and audiobook are available to you today and for 30 days<br /> after launch, once your copies arrive!
                </div>
              </center>
            </div>



          </center>
        </div>
      </div>


      <div className="bg-black ">
        <br></br>
        <div className="container col-sm-8 py-4 ">
          <center>
            <div className="jett_scrollmenu">
              <div className="col-sm-6 jett_scrollmenu_in">
                <div className="text_spread_box006">BOOK TRAILER</div>
                <div className="video_part_cont video_part_cont_new py-4">
                  <iframe className='iframe_part3' src="https://player.vimeo.com/video/1001246468" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
                </div>
              </div>



              <div className="col-sm-6 jett_scrollmenu_in">
                <div className="text_spread_box006">PRAISES</div>
                <div className="video_part_cont video_part_cont_new py-4">
                  <iframe className='iframe_part3' src="https://player.vimeo.com/video/957777985" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
                </div>
              </div>

            </div>


            <div className="col-sm-6 m-auto">
              <div className="new_audio0398 px-2 py-2">AUDIO EXCERPT</div>
              <div className="py-2">
                <AudioPlayer />
              </div>
            </div>

          </center>
        </div>

      </div>


      <div className="bg-white py-5">
        <div className=" ">
          <center>
            <div className="text_spread_box005 text-black">
              DON’T WAIT—UNLOCK VIP ACCESS NOW <br />BEFORE IT’S TOO LATE!
            </div>

            <div className="mt-4">
              <a className="btn_spread002 " href="/BuyPhysicalBook">UNLOCK ACCESS NOW!</a>
            </div>
          </center>
        </div>
      </div>



      {/* ===========Header notification======== */}
      <div className='header_time header_time_new '>
        <div className='col-sm-8 m-auto py-3'>
          <div class="row">
            <div class="col-md-auto">
              <div className='align-self-center'>
                <img className='img_time' src={'/images/icons/clock/1.gif'} />
              </div>
            </div>
            <div class="col-md-auto">
              <div className=' mt-2'>
                <div className='time_text1 mt-2'> CLAIM THIS SPECIAL DEAL—HURRY!</div>
                <div className='text0_spread'>Note: This special pre-launch offer could end in any minute...</div>
                {/* <div className='time_text3'> <Countdown targetDate={offerEndDate} /></div> */}
              </div>
            </div>
            <div class="col-md-auto">
              <div className=' align-self-center mb-3 mt-4 '>
                <span className="text1_spread">$21.99</span>  <span className="text2_spread">$18.69</span>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<a className="btn_spread001 " href="/BuyPhysicalBook">PRE-ORDER NOW!</a>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ====================== */}



    </div>
  )
}



export default ClaimSpecial