import Header from "../Header";
import Footer from "../Footer";
import Video_Junior from "../Video_Junior";
import React, { useState } from "react";
import { useNavigate, Link } from 'react-router-dom';
import { Modal, Button } from 'react-bootstrap';
import AudioPlayer from "./AudioPlayer";


function BuyDetails() {
  const [displayData, setDisplayData] = useState('data1');
  const [selectedButton, setSelectedButton] = useState('PAPERBACK');

  const showData1 = () => {
    setDisplayData('data1');
    setSelectedButton('PAPERBACK');
  };

  const showData2 = () => {
    setDisplayData('data2');
    setSelectedButton('HARDCOVER');
  };

  // New functions
  const showData3 = () => {
    setDisplayData('data3');
    setSelectedButton('EBOOK');
  };

  const showData4 = () => {
    setDisplayData('data4');
    setSelectedButton('AUDIOBOOK');
  };
  // ================================Model======================
  const [showModal, setShowModal] = useState(null);

  const handleShow = (modalName) => {
    setShowModal(modalName);
  };

  const handleClose = () => {
    setShowModal(null);
  };
  // ======================================================
  return (
    <div>
      <Header />


      <div className="speaking_slider_all d-flex justify-content-center px-4">
        <div className="col-sm-10 py-3">
          <div className="buyDetails_title1">START YOUR PRE-ORDER NOW</div>
        </div>
      </div>

      <center>
        <div id="BUY" className="col-sm-8 m-auto mt-2">
          <div id="PRE-ORDER">
            {/* =============PAPERBACK */}
            {displayData === 'data1' &&
              <div className="col-sm-4 m-auto">
                <img src={'/images/paperback.png'} className="img_buy_detail895" />
              </div>
            }

            {/* =============HARDCOVER */}
            {displayData === 'data2' &&
              <div className="col-sm-4 m-auto">
                <img src={'/images/hardcover.png'} className="img_buy_detail895" />
              </div>
            }
            {/* =============EBOOK */}
            {displayData === 'data3' &&
              <div className="col-sm-4 m-auto">
                <img src={'/images/book/ebookNew.png'} className="img_buy_detail895" />
              </div>
            }
            {/* =============AUDIOBOOK */}
            {displayData === 'data4' &&
              <div className="col-sm-4 m-auto">
                <img src={'/images/book/audiobookNew.png'} className="img_buy_detail895" />
              </div>
            }

          </div>
        </div>
      </center>


      {/* =========================================== */}

      <div className="col-sm-4 m-auto">
        <center>
          <div className="jett_scrollmenu">
            <div className="col-sm-3 jett_scrollmenu_in">
              <a href="#" onClick={() => handleShow('trailer')}>
                <img src={'/images/icons/kaptab/1.png'} className="kaptap_img" />
                <div className="kaptab_text">BOOK TRAILER</div>
              </a>
            </div>
            <div className="col-sm-3 jett_scrollmenu_in">
              <a href="#" onClick={() => handleShow('pdf')}>
                <img src={'/images/icons/kaptab/2.png'} className="kaptap_img" />
                <div className="kaptab_text">PDF EXCERPT</div>
              </a>
            </div>
            <div className="col-sm-3 jett_scrollmenu_in">
              <a href="#" onClick={() => handleShow('audio')}>
                <img src={'/images/icons/kaptab/3.png'} className="kaptap_img" />
                <div className="kaptab_text">AUDIO EXCERPT</div>
              </a>
            </div>
            <div className="col-sm-3 jett_scrollmenu_in">
              <a href="#" onClick={() => handleShow('blurb')}>
                <img src={'/images/icons/kaptab/4.png'} className="kaptap_img" />
                <div className="kaptab_text">BOOK BLURB</div>
              </a>
            </div>
          </div>
        </center>
      </div>



      {/* Modals */}
      <Modal show={showModal === 'trailer'} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Book Trailer</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className=''>
            <iframe className='bt_video875' src="https://player.vimeo.com/video/1001246468" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      {/* ===================================== */}

      <Modal show={showModal === 'pdf'} onHide={handleClose} size="xl">
        <Modal.Header closeButton>
          <Modal.Title>Book Excerpt</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="resume_book space0098">
            <center>
              <div className="col-sm-8">
                <div className='blog_scrol_jett'>
                  <img className="image_book_mobile jett_scrollmenu_in" src={'/pdf/img_book/home_persist-1.png'} />
                  <img className="image_book_mobile jett_scrollmenu_in" src={'/pdf/img_book/home_persist-2.png'} />
                </div>
              </div>
            </center>
          </div>


          {/* =============================Mobile=================== */}

          {/* ====== */}

        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      {/* ===================================== */}

      <Modal show={showModal === 'audio'} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Audio Excerpt</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="bg-black px-2 py-2">
            <AudioPlayer />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      {/* ===================================== */}

      <Modal show={showModal === 'blurb'} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Book Blurb</Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <div className="sum_book_7645 " id="PERSIST">
            <div className="d-flex align-items-center justify-content-center">
              <div className="col-sm-10 ">
                <div className="buy_new_1 pt-2">PERSIST, PERSIST, PERSIST.</div>
                <div className="">
                  <div className="buy_new_2 ">The Haitian Boy, The American, </div>
                  <div className="buy_new_2 ">and The Hidden Lesson</div>

                  <div className="book_part3_text mt-3 ">

                    In a world that often dismisses the pursuit of dreams as a mere luxury, for Junior, it was an all-out war between life and death.
                    <br /><br />
                    Growing up in the heart of poverty in Haiti, a world vividly described in this memoir, Junior embarked on an unprecedented journey—from surviving a heart-wrenching car crash to desperate street begging in the Dominican Republic. Then came the Haiti earthquake, a relentless force that snuffed out over 200k lives in less than 60 seconds.
                    <br /><br />
                    And if you think you've heard it all, think again. What lies ahead in these pages takes “persistence” to a whole new dimension—a story so gripping, so raw, that you need to read it to believe it.
                    <br /><br />
                    "Persist, Persist, Persist" is not just a memoir; it's an electrifying, can't-put-it-down chronicle that immerses you in Junior's thrilling journey and unveils Haiti's narrative in a revolutionary light. A timeless tale of obsession, survival, and self-invention, intricately woven with the unforgiving twists of fate.
                    <br /><br />
                    Brace yourself for a narrative that will make you lose track of time and demand to be shared with everyone you know.
                  </div>

                  <div className="mt-4">
                    <img src={'/images/buy_footer1234.png'} className="persist_img_page8474" />
                  </div>

                </div>

              </div>
            </div>

          </div>



        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      {/* =========================================== */}






      <hr />

      <div className="container inspire_space2">
        <div className="inspire_space_btn mb-5">
          <center>
            <button
              className={`buyDetails_btn1 px-5 mx-1 mb-2 ${selectedButton === 'PAPERBACK' ? 'selected' : ''}`}
              onClick={showData1}
            > PAPERBACK
            </button>
            {/* <button
              className={`buyDetails_btn1 px-5  mx-1 mb-3 ${selectedButton === 'HARDCOVER' ? 'selected' : ''}`}
              onClick={showData2} >
              HARDCOVER
            </button> */}


            <button
              className={`buyDetails_btn2  mx-1 mb-2 ${selectedButton === 'EBOOK' ? 'selected' : ''}`}
              onClick={showData3}
            >
              EBOOK
            </button>
            <button
              className={`buyDetails_btn1 px-5 mx-1 mb-2 ${selectedButton === 'AUDIOBOOK' ? 'selected' : ''}`}
              onClick={showData4}
            >
              AUDIOBOOK
            </button>
          </center>
        </div>
      </div>


      {/* =================================================*/}


      <div className="">
        <div id="PRE-ORDER">
          {/* =============PAPERBACK */}
          {displayData === 'data1' &&
            <div className="">
              <div className="buyDetails_box1 ">
                <span className="buyDetails_box1_text1">PRICE:</span>
                <span className="buyDetails_box1_text2 px-1">$21.99</span>
                <span className="buyDetails_box1_text3 px-2">$18.69</span>
              </div>


              <center>
                <div className="py-3 mt-3">
                  <Link className="buy_pre_new001" to={'/BuyPhysicalBook'}>PRE-ORDER BOOK NOW</Link>
                </div>

                {/* <div className=" col-sm-6 m-auto">
                  <table className="buyDetails_table">
                    <tbody>

                      <tr>
                        <td><img src={'/images/Book_Distributors_Logo/1.png'} className="img_Book_Distributors_Logo" /></td>
                        <td><a title="BAM" target="_blank" href="https://www.booksamillion.com/p/Persist/Junior-Bernard/9798989600106?id=9250240420970" className="buyDetails_btn_purchase">PURCHASE</a></td>
                      </tr>
                      <tr>
                        <td><img src={'/images/Book_Distributors_Logo/3.png'} className="img_Book_Distributors_Logo" /></td>
                        <td><a title="BARNES & NOBLE" target="_blank" href="https://www.barnesandnoble.com/w/persist-persist-persist-junior-bernard/1146253152?ean=9798989600106" className="buyDetails_btn_purchase">PURCHASE</a></td>
                      </tr>
                    </tbody>
                  </table>
                </div> */}
                {/* 
                <div className="buyDetails_box1_text4 mt-4 col-sm-8 m-auto">Coming Soon Worldwide, including...</div>
                <div className=" col-sm-6 m-auto opacity-25">
                  <table className="buyDetails_table">
                    <tbody>
                      <tr>
                        <td><img src={'/images/Book_Distributors_Logo/2.png'} className="img_Book_Distributors_Logo" /></td>
                        <td><span href="" className="buyDetails_btn_purchase">PURCHASE</span></td>
                      </tr>
                    
                      <tr>
                        <td><img src={'/images/Book_Distributors_Logo/7.png'} className="img_Book_Distributors_Logo" /></td>
                        <td><span href="" className="buyDetails_btn_purchase">PURCHASE</span></td>
                      </tr>

                    </tbody>
                  </table>
                </div> */}
              </center>

            </div>
          }

          {/* =============HARDCOVER */}
          {displayData === 'data2' &&
            <div className="">
              <div className="buyDetails_box1 ">
                <span className="buyDetails_box1_text1">PRICE:</span>
                <span className="buyDetails_box1_text2 px-1">$32.99</span>
                <span className="buyDetails_box1_text3 px-2">$29.69</span>
              </div>



              <center>
                <div className="py-3 mt-3">
                  <Link className="buy_pre_new001" to={'/BuyPhysicalBook'}> PRE-ORDER BOOK NOW</Link>
                </div>

                <div className="buyDetails_box1_text4 mt-4 col-sm-8 m-auto">Coming Soon Worldwide, including...</div>
                <div className=" col-sm-6 m-auto opacity-25">
                  <table className="buyDetails_table">
                    <tbody>
                      <tr>
                        <td><img src={'/images/Book_Distributors_Logo/2.png'} className="img_Book_Distributors_Logo" /></td>
                        <td><span href="" className="buyDetails_btn_purchase">PURCHASE</span></td>
                      </tr>
                      <tr>
                        <td><img src={'/images/Book_Distributors_Logo/1.png'} className="img_Book_Distributors_Logo" /></td>
                        <td><span href="" className="buyDetails_btn_purchase">PURCHASE</span></td>
                      </tr>
                      <tr>
                        <td><img src={'/images/Book_Distributors_Logo/3.png'} className="img_Book_Distributors_Logo" /></td>
                        <td><span href="" className="buyDetails_btn_purchase">PURCHASE</span></td>
                      </tr>
                      <tr>
                        <td><img src={'/images/Book_Distributors_Logo/7.png'} className="img_Book_Distributors_Logo" /></td>
                        <td><span href="" className="buyDetails_btn_purchase">PURCHASE</span></td>
                      </tr>

                    </tbody>
                  </table>
                </div>
              </center>


            </div>
          }
          {/* =============Ebook */}
          {displayData === 'data3' &&
            <div className="">
              <div className="buyDetails_box1 ">
                <span className="buyDetails_box1_text1">PRICE:</span>
                <span className="buyDetails_box1_text2 px-1">$9.99</span>
                <span className="buyDetails_box1_text3 px-2">$8.49</span>
              </div>

              <center>

                <div className="py-3 mt-3">
                  <Link className="buy_pre_new001" to={'/BuyAudiobookEbook'}><i class="bi bi-arrow-return-right"></i> PRE-ORDER EBOOK NOW</Link>
                </div>

                {/* <div className="buyDetails_box1_text4 mt-4 col-sm-8 m-auto">Also Available for Purchase From:</div>
                <div className=" col-sm-6 m-auto">
                  <table className="buyDetails_table">
                    <tbody>
                      <tr>
                        <td><img src={'/images/Book_Distributors_Logo/2.png'} className="img_Book_Distributors_Logo" /></td>
                        <td><a href="https://www.amazon.com/dp/B0DG4R698Z?ref_=pe_93986420_775043100"
                          target="_blank" className="buyDetails_btn_purchase">PURCHASE</a></td>
                      </tr>
                      <tr>
                        <td><img src={'/images/Book_Distributors_Logo/3.png'} className="img_Book_Distributors_Logo" /></td>
                        <td><a href="https://www.barnesandnoble.com/w/persist-persist-persist-junior-bernard/1146245845?ean=2940179998228"
                          target="_blank" className="buyDetails_btn_purchase">PURCHASE</a></td>
                      </tr>
                      <tr>
                        <td><img src={'/images/Book_Distributors_Logo/4.png'} className="img_Book_Distributors_Logo" /></td>
                        <td><a href="https://books.apple.com/us/book/persist-persist-persist-the-haitian-boy-the/id6670446839"
                          target="_blank" className="buyDetails_btn_purchase">PURCHASE</a></td>
                      </tr>
                      <tr>
                        <td><img src={'/images/Book_Distributors_Logo/6.png'} className="img_Book_Distributors_Logo" /></td>
                        <td><a href="https://www.smashwords.com/books/view/1612132"
                          target="_blank" className="buyDetails_btn_purchase">PURCHASE</a></td>
                      </tr>

                    </tbody>
                  </table>
                </div> */}
              </center>

            </div>
          }
          {/* =============Audiobook */}
          {displayData === 'data4' &&
            <div className="">
              <div className="buyDetails_box1 ">
                <span className="buyDetails_box1_text1">PRICE:</span>
                <span className="buyDetails_box1_text2 px-1">$17.64</span>
                <span className="buyDetails_box1_text3 px-2">$14.99</span>
              </div>

              <center>
                <div className="py-3 mt-3">
                  <Link className="buy_pre_new001" to={'/BuyAudiobookEbook'}><i class="bi bi-arrow-return-right"></i> PRE-ORDER AUDIOBOOK NOW</Link>
                </div>
                
              </center>

            </div>
          }

        </div>
      </div>


      {/* ============================Bar-shipping*/}

      {/* <div className="bar_jett_sep001 d-none d-sm-block"></div> */}




      <div className="bg_84763543 my-5">
        <div className="container py-3 d-none d-sm-block">
          <div className="row ">
            <div className="col-sm-3"><img src={'/images/book/5.png'} className="image763437" /></div>
            <div className="col-sm-4 mt-4">
              <center>
                <div className="buyDetails_text001 mb-2">ORDERING 25+ PAPERBACK COPIES?</div>
                <Link to={'/Buy25'} className="btn_spread001">ENTER AMOUNT</Link>
              </center>
            </div>
            <div className="col-sm-5 mt-1"><img src={'/images/heading_picture984.png'} className="image763437984" /></div>
          </div>
        </div>
      </div>






      {/* ================================================= */}
      <br />
      <div className="spread_box_video">
        <div className="spread_box_video_title pt-4">PRAISE</div>
        {/*  */}
        <div className="container col-sm-9 mt-4">
          <div className="row">

            <div className="col-sm-4">
              <div className="video_part_cont mb-4">
                <iframe className='iframe_part3' src="https://player.vimeo.com/video/956163210" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
                <div className="part2_video_cont py-4">
                  <div className="text1_part_cont">Review from Former Professor</div>
                  <div className="text2_part_cont ">Book review from university professor.</div>
                </div>
              </div>
            </div>


            <div className="col-sm-4">
              <div className="video_part_cont mb-4">
                <iframe className='iframe_part3' src="https://player.vimeo.com/video/957777985" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
                <div className="part2_video_cont py-4">
                  <div className="text1_part_cont">Readers' Reactions </div>
                  <div className="text2_part_cont ">"It's an absolute masterpiece."</div>
                </div>
              </div>
            </div>


            <div className="col-sm-4">
              <div className="video_part_cont mb-4">
                <iframe className='iframe_part3' src="https://player.vimeo.com/video/950825473" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
                <div className="part2_video_cont py-4">
                  <div className="text1_part_cont">University Student</div>
                  <div className="text2_part_cont ">Serenity's impression of 'Persist, Persist, Persist.'</div>
                </div>
              </div>
            </div>


          </div>
          <br />  <br />
        </div>
        {/*  */}
      </div>



      <Footer />
    </div>
  )
}



export default BuyDetails