import React, { useState } from "react";
import Header from "../Header";
import Footer from "../Footer";
import { useNavigate, Link } from 'react-router-dom';
function BuyAudiobookEbook() {
  const navigate = useNavigate();

  const ebook_normal = 9.99;
  const ebook = 8.99;
  const ebook_inf = 8.49;

  const audiobook_normal = 17.64;
  const audiobook = 14.99;
  const audiobook_inf = 14.99;

  // ==========
  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };
  const [isChecked, setIsChecked] = useState(false);

  const [input, setInput] = useState({
    qty_ebook: "1",
    name: "",
    phone: "",
    email: "",
    description_ebook: "",


    // ======

    qty_audiobook: "1",
    name2: "",
    phone2: "",
    email2: "",
    description_audiobook: "",

    influencer_id: "",
    street: "",
    city: "",
    state_province: "",
    postal_ZIP_code: "",
    country: "",
  });

  const handleChange = (e) => {
    let newValue = {
      ...input,
      [e.target.name]: e.target.value,
    };
    setInput(newValue);
  };
  // ============EBOOK================


  const [error, setError] = useState("");
  const [error2, setError2] = useState("");

  const CheckEbook = (e) => {
    e.preventDefault();
    setError("");
    if (input.name && input.phone && input.email) {
      try {



        navigate('/Buy_checkout_no_influencer',
          {
            state: {
              type: "EBOOK",
              qty: input.qty_ebook,
              amount: input.qty_ebook * ebook,
              name: input.name,
              phone: input.phone,
              email: input.email,
              description: input.description_ebook,
              country: input.country
            }
          });


      } catch (error) {
        console.log(error);
      }
    } else {
      setError("Error: Missing information");
    }
  }

  // =============AudioBook===============

  const CheckAudioBook = (e) => {
    e.preventDefault();
    setError2("");
    if (input.name2 && input.phone2 && input.email2) {
      try {

        navigate('/Buy_checkout_no_influencer',
          {
            state: {
              type: "AUDIOBOOK",
              qty: input.qty_audiobook,
              amount: input.qty_audiobook * audiobook,
              name: input.name2,
              phone: input.phone2,
              email: input.email2,
              description: input.description_audiobook,
              country: input.country
            }
          });
      } catch (error2) {
        console.log(error2);
      }
    } else {
      setError2("Error: Missing information");
    }
  }
  return (
    <div>
      <Header />
      <div className="book">
        <center>
          <div className=" slider_book_text1 h2 text-white">PRE-ORDER EBOOK/AUDIOBOOK NOW</div>

        </center>
      </div>


      {/* ======================================================= */}

    

      <div className="buy_7585 ">
        <div className="container">
          <div className="row">




            {/*=============================================  */}
           

            {/*========================EBOOK=====================  */}
            <div className="col-sm-6">
              <div className="int_cont1_buy ">
                <center>
                  <img className="buy_image001" src={'images/book/1.png'} />
                  <h3 className="py-4 text-white">GET THE EBOOK</h3>
                </center>

                <div className="bar_cont1_buy">
                  <center>

                    <div className="text_bar_cont1_buy">
                      <table className="buy_table">
                        <tr>
                          {/* <td><span className="buy_text56">Price:</span> $ {Number(ebook).toFixed(2)}</td> */}
                          <td><span className="buy_text56">Price: </span>
                            {/* <span className="text-decoration-line-through"> ${Number(ebook).toFixed(2)}</span> */}

                            <span className="text-decoration-line-through"> ${Number(ebook_normal).toFixed(2)}</span>
                            &nbsp; &nbsp;${Number(ebook).toFixed(2)}
                          </td>
                          <td>&nbsp;&nbsp;&nbsp;&nbsp;</td>
                          <td><span className="buy_text56">Quantity: </span></td>
                          <td><input className="buy_input_98" name="qty_ebook" value={input.qty_ebook} type="number" onChange={handleChange} /></td>




                        </tr>
                      </table>
                      <div className="text_disc">Great news! You're about to save 10% OFF this order.</div>
                    </div>
                  </center>
                </div>

                <div className="container px-4 pb-3">
                  <div className="cont_cent_buy_7468">
                    <div className="cont2_cent_buy_7468">
                      <table className="table_buy_345">
                        <tr>
                          <td>Subtotal:</td>
                          <td>$ {Number(input.qty_ebook * ebook).toFixed(2)}</td>
                        </tr>

                        <tr>
                          <td className="total_buy">Total:</td>
                          <td className="total_buy">$ {Number(input.qty_ebook * ebook).toFixed(2)} USD</td>
                        </tr>
                      </table>
                      <p className="text_tax_buy">Taxes and shipping calculated at checkout</p>
                      {error && <div className='error_text'>{error}</div>}
                      <input className="input_part2_jett2" placeholder='Name' name="name" type="text" id="name" required="" onChange={handleChange} />
                      <input className="input_part2_jett2" placeholder='Phone' name="phone" type="text" id="phone" required="" onChange={handleChange} />
                      <input className="input_part2_jett2" placeholder='Email Address' name="email" type="text" id="email" required="" onChange={handleChange} />
                      <select className="input_part2_jett2" name="country" id="country" required onChange={handleChange}>
                        <option value="" disabled selected>Select your country</option>
                        <option value="USA">USA</option>
                        <option value="Canada">Canada</option>
                        <option value="United Kingdom">United Kingdom</option>
                        <option value="France">France</option>
                        <option value="Germany">Germany</option>
                        <option value="Haiti">Haiti</option>
                        <option value="Kenya">Kenya</option>
                      </select>
                      <textarea className="input_part2_jett2" placeholder="Order note" name="description_ebook" onChange={handleChange} ></textarea>
                      <div>
                        <center>
                          <div className="mt-2 mb-4">
                            <button onClick={(e) => { CheckEbook(e) }} className="btn_buy_now " ><i className="bi bi-lock px-2"></i>PROCEED TO SAFE CHECKOUT</button>
                          </div>
                        </center>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>


{/* ===========AUDIOBOOK============== */}

            <div className="col-sm-6">
              <div className="int_cont1_buy ">
                <center>
                  <img className="buy_image001" src={'images/book/audio_home2.png'} />
                  <h3 className="py-4 text-white">GET THE AUDIOBOOK</h3>
                </center>

                <div className="bar_cont2_buy py-5">
                  <center>
                    <table className="buy_table">
                      <tr>
                        {/* <td><span className="buy_text56">Price:</span> $ {Number(audiobook).toFixed(2)}</td> */}
                        <td><span className="buy_text56">Price:</span>
                          {/* <span className="text-decoration-line-through"> ${Number(audiobook).toFixed(2)}</span> */}
                          
                          


                          <span className="text-decoration-line-through"> ${Number(audiobook_normal).toFixed(2)}</span>
                          &nbsp; &nbsp;${Number(audiobook).toFixed(2)}
                        </td>
                        <td>&nbsp;&nbsp;&nbsp;&nbsp;</td>
                        <td><span className="buy_text56">Quantity: </span></td>
                        <td><input className="buy_input_98" name="qty_audiobook" value={input.qty_audiobook} type="number" onChange={handleChange} /></td>
                      </tr>
                    </table>
                    <div className="text_disc">Great news! You're about to save 10% OFF this order.</div>
                  </center>
                </div>

                <div className="container px-4 pb-3">
                  <div className="cont_cent_buy_7468">
                    <div className="cont2_cent_buy_7468">
                      <table className="table_buy_345">
                        <tr>
                          <td>Subtotal:</td>
                          <td>$ {Number(input.qty_audiobook * audiobook).toFixed(2)}</td>
                        </tr>

                        <tr>
                          <td className="total_buy">Total:</td>
                          <td className="total_buy">$ {Number(input.qty_audiobook * audiobook).toFixed(2)} USD</td>
                        </tr>
                      </table>

                     
                      <p className="text_tax_buy">Taxes and shipping calculated at checkout</p>
                      {error2 && <div className='error_text'>{error2}</div>}
                      <input className="input_part2_jett2" placeholder='Name' name="name2" type="text" id="nam2e" required="" onChange={handleChange} />
                      <input className="input_part2_jett2" placeholder='Phone' name="phone2" type="text" id="phone2" required="" onChange={handleChange} />
                      <input className="input_part2_jett2" placeholder='Email Address' name="email2" type="text" id="email2" required="" onChange={handleChange} />
    <select className="input_part2_jett2" name="country" id="country" required onChange={handleChange}>
                            <option value="" disabled selected>Select your country</option>
                            <option value="USA">USA</option>
                            <option value="Canada">Canada</option>
                            <option value="United Kingdom">United Kingdom</option>
                            <option value="France">France</option>
                            <option value="Germany">Germany</option>
                            <option value="Haiti">Haiti</option>
                            <option value="Kenya">Kenya</option>
                          </select>
                      <textarea className="input_part2_jett2" name="description_audiobook" onChange={handleChange} placeholder="Order note"></textarea>
                  



                      <div>
                        <center>
                          <div className="mt-2">
                            <button onClick={(e) => { CheckAudioBook(e) }} className="btn_buy_now2 " ><i className="bi bi-lock px-2"></i>PROCEED TO SAFE CHECKOUT</button>
                          </div>
                        </center>
                      </div>
                    </div>
                    <div className="d-none d-sm-block"><br /></div>
                  </div>
                </div>

              </div>
            </div>








          </div>
        </div>

        <div className="d-none d-sm-block"><br /></div>

        {/* ======================================================= */}

        {/* <div id="PREORDER" className="">
          <div className="bg_84763543">
            <div className="container py-3 d-none d-sm-block">
              <div className="row ">
                <div className="col-sm-6"><img src={'/images/book/5.png'} className="image763437" /></div>
                <div className="col-sm-6"><img src={'/images/heading_picture984.png'} className="image763437984" /></div>
              </div>
            </div>
          </div>
          <div className="buy_por984774_text1 mt-3">PRE-ORDER IN BULK NOW AND SPREAD THE IMPACT</div>
          <div className="buy_por984774_text2 py-2"> Buy a bundle and share your spare copies with< br /> friends, family, or your organization.</div>

          <center>
            <div className="col-sm-6 buy_box1 py-5 mt-5 ">
              <div className="buy_box_text1 px-2">PRE-ORDER 10+ HARDCOVER COPIES</div>
              <div className="buy_box_text1 color_main_jaune px-2"> (FREE SHIPPING INCLUDED!)</div>
              <br />
              <div className="buy_box_btn_cont">
                <a href='/Buy10' className="buy_box_btn px-3 pb-2">ORDER NOW</a>
              </div>
            </div>

            <br /><br />
            <div>
              <center>
                <div className="h5 ">EXPLORE MORE WAYS TO BUY IN BULK<br /> AND INSPIRE YOUR COMMUNITY</div>
                <br />
                <div className="col-sm-7 mt-2">
                  <div className="row">
                    <div className="col-sm-3 mb-4">
                      <a href={'/InspireSchools'} className="btn_inspire_buy">SCHOOLS</a>
                    </div>

                    <div className="col-sm-3 mb-4">
                      <a href={'/InspireCompanies'} className="btn_inspire_buy">COMPANIES</a>
                    </div>

                    <div className="col-sm-3 mb-4">
                      <a href={'/InspireChurches'} className="btn_inspire_buy">CHURCHES</a>
                    </div>

                    <div className="col-sm-3 mb-4">
                      <a href={'/InspireBook_clubs'} className="btn_inspire_buy">BOOK CLUBS</a>
                    </div>
                  </div>
                </div>
              </center>

            </div>





            <div className="bg-black mt-5">
              <div className="col-sm-4 py-5">
                <div className="">
                  <img src={"/images/payment_type.png"} className="payment_image_accept" />
                </div>
              </div>
            </div>

          </center>

        </div> */}


        {/* ===================================== */}





      </div>
      {/* ===================================== */}
{/* 
      <div className="last_payment_page984 py-3 mb-2">
        <center>
          <div className="col-sm-10">
            <img src={'/images/buy_footer123.png'} className="img_buy_footer123" />
          </div>
        </center>
      </div> */}












      <Footer />
    </div>
  )
}


export default BuyAudiobookEbook